<template>

    <MobileHeader  :mobileHeaderTitle="'COPA_2024_MOBILE'" ></MobileHeader>
    <!-- <div v-if="!isMobile" class="worlcup-top__section ">
        <Banner  v-if="currentTab === 'copa2024Matches' || currentTab === 'copa2024Standings'"></Banner>
        <div v-show="currentTab === 'copa2024Bracket'" class="container worlcup-top__section-bracket flex-col">
            <img src="../../../../static/images/worldcup2022/fifa_worldcup_2022_flag.png">
            <h2>International | World Cup 2022 &trade;</h2>
            <div class="worldcup-desc">World Cup</div>
        </div>
    </div> -->
    <div class="container has-space has-header worldcup-hasheader" :class="{'remove-space': currentTab=== 'FIFA2022Bracket'}">
        <div class="display-flex home-nav__wrapper" :class="{'has-space': currentTab=== 'FIFA2022Bracket'}">
            <template v-for="(o, index) in tabs" :key=index>
                <router-link :to="{ name: o.pathName}" v-if="o.name !== 'STANDINGS' && !isMobile" class="sub-nav__child" :key=index>
                    <span class="sub-nav__child-text">{{ $t(o.name) }}</span>
                    <div
                        class="sub-nav__child-indicator"
                        :class="{ 'nav-selected': o.name === currentPage }"
                    ></div>
                </router-link>
            </template>
            <template v-for="(o, index) in tabs" :key=index>
                <router-link :to="{ name: o.pathName}" v-if="isMobile" class="sub-nav__child" :key=index>
                    <span class="sub-nav__child-text">{{ $t(o.name) }}</span>
                    <div
                        class="sub-nav__child-indicator"
                        :class="{ 'nav-selected': o.name === currentPage }"
                    ></div>
                </router-link>
            </template>
        </div>
        <div class="">
            <router-view ></router-view> 
        </div>
    </div>
</template>

<script>
import MobileHeader from '@/components/mobile/Header.vue'
import {mapGetters,mapActions} from 'vuex'
import Banner from "@/components/Banner.vue";

import config from '@/js/config.js'
export default {
	components:{  
		MobileHeader,    
        Banner
    },
    data() {
        return {
            s3ImgUrl: config.s3ImgUrl,
            tabs: [
                {
                    name: "MATCHES",
                    pathName: "copa2024Matches",
                    selected: true,
                },
                {
                    name: "STANDINGS",
                    pathName: "copa2024Standings",
                    selected: false
                },
                {
                    name: "BRACKET",
                    pathName: "copa2024Bracket",
                    selected: false,
                },
 
            ],

            currentTab: []
        }
    },
    mounted() {
        this.currentTab = this.$route.name;  
    },

    computed: {
        ...mapGetters([              
                'isMobile',
                'currentLocale',
                'currentLanguageObj',
                'isLogin'
           ]),
    },

    watch:{
        $route() {
            this.currentTab = this.$route.name;
        },
    },

    created() {
          
    },
    methods: {
    ...mapActions([
                "getContentList",
            ]),
    },
}
</script>

<style scoped>
.worldcup-desc{
    font-size: 0.875rem
}
.worlcup-top__section-bracket{
    display: flex;
    align-items: center;
}
.worlcup-top__section{
    /* height: 12rem; */
    margin-bottom: 3.563rem;
}

.home-nav__wrapper {
    margin-bottom: 0.75rem;
}
.home-nav__child-text {
    font-weight: 700;
    color: #6F8C9B;
}
@media (max-width: 768px) {
    .worldcup-hasheader{
        padding-top: 4rem;
    }
    .remove-space{
        margin-left: 0;
        margin-right: 0;
    }
}

</style>